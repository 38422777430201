import { Col, Form, Row, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutPagination } from 'components/layout.component';
import { ViewMemberGroupList } from "components/member.component";
import React, { useEffect, useState } from 'react';
import { $get } from 'services';

// 會員群組

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);

  // 取得會員群組
  const { data: memberGroupList, isValidating: isValidatingMemberGroupList, mutate: mutateMemberGroupList } = $get({
    url: 'api/membergroup/list',
    params: {
      PageIndex: page[0],
      PageSize: page[1],
    },
    showMessage: false
  })

  // 取得全部會員群組
  const { data: memberGroupAllList, isValidating: isValidatingMemberGroupAllList, mutate: mutateMemberGroupAllList } = $get({
    url: 'api/membergroup/all/list',
    showMessage: false
  })

  const [dataList, setDataList] = useState<any>([])
  useEffect(() => {
    if (memberGroupList?.Data) setDataList(memberGroupList.Data)
  }, [memberGroupList]);

  const mutate = () => {
    mutateMemberGroupList();
    mutateMemberGroupAllList();
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} >
          <Row gutter={[12, 12]}>
            {/* 列表 */}
            <Col span={24}>
              <Spin spinning={isValidatingMemberGroupList}>
                <ViewMemberGroupList data={dataList} mutate={mutate} />
                <LayoutPagination total={memberGroupList?.TotalRecord} setPage={setPage} page={page} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content >
    </div >
  );
};

export default PageMain;